import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from "./Header";
import Footer from "./Footer";
import HijabsForEveryday from "./HijabsForEveryday";
import ItemAddedModal from "./ItemAddedModal";

import Loading from "../widget/Loading";

import Slider from "@mui/material/Slider";

import axios from 'axios';

import { AES } from 'crypto-js';


import NotificationModal from '../modals/NotificationModal';



export default function ShopPage({ options, cart, setSiteLanguage, addToCart, updateCart, removeCartItem, removeAllCartItem }) {
    const navigate = useNavigate();
    const [products, setProductsData] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isDataloading, setIsDataLoading] = useState(true);

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [range, setRange] = useState([minPrice, maxPrice]);
    const [useFilter, setUseFilter] = useState(false);
    const updateRange = async (r, b) => {
      setRange(r);
      setUseFilter(b);
    }



    //notification modal
    const [notificationType, setNotificationType] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const openNotificationModal = (type, title, message) => {
      setNotificationType(type);
      setNotificationTitle(title);
      setNotificationMessage(message);
  
      setIsNotificationModalOpen(true);
    };
    const closeNotificationModal = () => {
      setIsNotificationModalOpen(false);
    };
    //notification modal



    useEffect(() => {
        window.scrollTo(0, 0);
        handleData();
    }, []);

    const handleData = async () => {    

        setIsDataLoading(true);
        try {
    
          const response = await axios.get(process.env.REACT_APP_API_SERVER_URL +'/response/vh-read-items.php', {
            //params: { uid: uid },
            headers: {
              "Content-Type": "application/json",

            },
          });
    
          setIsDataLoading(false);
          // alert(JSON.stringify(response.data, null, 2));
    
          if (response.data.status) {
            //alert("dashboard-products " + JSON.stringify(response.data.itemsData, null, 2));
            const itemsData = response.data.itemsData;
    
            setProductsData(itemsData);
            setFilteredProducts(itemsData);

            // Find minimum and maximum prices
            let minPrice = Infinity;
            let maxPrice = -Infinity;
            itemsData.forEach(item => {
                const price = parseFloat(item.Price);
                if (!isNaN(price)) {
                minPrice = Math.min(minPrice, price);
                maxPrice = Math.max(maxPrice, price);
                }
            });
            setMinPrice(minPrice);
            setMaxPrice(maxPrice);

            setRange([minPrice, maxPrice]);


          } else {
            // alert("error1: " + response.data.message);
            openNotificationModal(false, "Valor Hijab", "error1: " + response.data.message);
          }
    
        } catch (error) {
          setIsDataLoading(false);
        // alert("error2: " + error);
          openNotificationModal(false, "Valor Hijab", "error2: " + error);
        }
      };

    

    function handleChanges(_, newValue) {
        setRange(newValue);
        updateRange(newValue, false);
      }
      
      
      const [showItemAdded, setShowItemAdded] = useState(false);
      const [showIndexItemAdded, setShowIndexItemAdded] = useState(-1);
      const showAddedDialogue = (i) => {
        setShowIndexItemAdded(i);
        // alert("");
        setShowItemAdded(true);
        setTimeout(() => {
            setShowItemAdded(false);
        }, 1000);
    }
    const [addedItemName, setAddedItemName] = useState('');


    const subscribeUser = () => {
        // alert("here");
        openNotificationModal(true, "Subscribe", "Here");
    }

    const applyFilter = async () => {
        updateRange(range, true);
        //alert(range[0] + " " + range[1]);

        const minPrice = range[0];
        const maxPrice = range[1];


        const filtered = products.filter(product => product.Price >= minPrice && product.Price <= maxPrice);
    
        // Update state with filtered products
        setFilteredProducts(filtered);
        //alert(filtered.length);

    }


    const [sortBy, setSortBy] = useState("");
    const handleDropdownSortByChange = (e) => {
      const sortBy = e.target.value;
      let sortedProducts = [...filteredProducts];
    
      switch (sortBy) {
        case 'individual': // Popularity sorting (Assuming there is a 'popularity' field)
          sortedProducts.sort((a, b) => b.popularity - a.popularity);
          break;
    
        case 'rating': // Sort by rating (Assuming there is a 'rating' field)
          sortedProducts.sort((a, b) => b.rating - a.rating);
          break;
    
        case 'newness': // Sort by creation date (newest first)
          sortedProducts.sort((a, b) => new Date(b.Created_On) - new Date(a.Created_On));
          break;
    
        case 'lowtohigh': // Sort by price (low to high)
          sortedProducts.sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));
          break;
    
        case 'hightolow': // Sort by price (high to low)
          sortedProducts.sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
          break;
    
        case 'random': // Random shuffle
          sortedProducts.sort(() => Math.random() - 0.5);
          break;
    
        case 'nameatoz': // Sort by name A-Z
          sortedProducts.sort((a, b) => a.Item_Name.localeCompare(b.Item_Name));
          break;
    
        case 'nameztoa': // Sort by name Z-A
          sortedProducts.sort((a, b) => b.Item_Name.localeCompare(a.Item_Name));
          break;
    
        default: // Default sorting (reset to original order)
          sortedProducts = [...products]; // Assuming 'products' holds the original data
      }
    
      setFilteredProducts(sortedProducts);
    };
    
    

    const [sortStock, setSortStock] = useState("");
    const handleDropdownSortStockChange = (event) => {
        const selectedValue = event.target.value;
        setSortStock(selectedValue);
      //
      //alert(selectedValue);
    };


    const [ratings, setRatings] = useState({
        oneStar: false,
        twoStars: false,
        threeStars: false,
        fourStars: false,
        fiveStars: false,
      });    
      const handleChange = (event) => {
        setRatings({ ...ratings, [event.target.name]: event.target.checked });
      };


      const navigateToProduct = (product) => {
        const encryptedData = AES.encrypt(JSON.stringify(product), 'encryptionKey').toString();
          navigate('/product-details', { state: { encryptedData } });
      };




      
    return (
    <div className='relative  bg-valorHijabTheme ' style={{  }}>
        <Header options={options} cart={cart} setSiteLanguage={setSiteLanguage} addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem}/>

        <img 
    src={'https://www.valorhijab.com/api/v1/vh-images/Pleated.jpeg'} //{process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + parsedProduct.Image_Url}
    alt='overlay' 
    className='absolute inset-0 w-full h-full object-cover opacity-20' 
  />


        <div className="
        
        md:px-4 lg:px-16 xl:px-24 2xl:px-64 
        overflow-hidden">
            {/* <div className='font-bold' style={{ fontSize: '20px' }}>Few Deals Close To You</div> */}

            <div className="flex flex-col md:flex-row 
            px-8 md:px-16
            " style={{  }}>
                
                <div className="w-30 md:w-1/3 lg:w-1/4 xl:w-30 py-4 mt-4 relative z-20  ">
                    <div className='bg-gray-300 px-2 py-4 text-sm   rounded-t-lg pl-4'>Filter Hijabs</div>
                    <div className='bg-gray-100 pt-2 pb-2  rounded-b-lg  px-10'> 
                        <div className='mt-4 '>
                            <Slider
                            min={minPrice} max={maxPrice} value={range} onChange={handleChanges} valueLabelDisplay="auto"
                            sx={{ color: '#4B181F', //'black',
                            '& .MuiSlider-thumb': { backgroundColor: '#4B181F', width: 12, height: 12, borderRadius: '4px'},
                            '& .MuiSlider-track': { backgroundColor: '#4B181F', },
                            }}
                        />
                        </div>
                        
                        <div className='flex flex-col mb-4'>
                            <div style={{ marginTop: '8px', marginBottom: '10px' }}>{'$' + 
                                range[0].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' - $' + 
                                range[1].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                }
                            </div>
                            <span className='text-xs h-4' style={{ }}>{useFilter ? 'Price filter applied' : ""}</span>


                            <div className="flex flex-col py-2 ">
                                <label>Sort By:</label>
                                <select 
                                className="mt-2 p-2 focus border-valorHijabTheme border-2 "
                                onChange={(e) => handleDropdownSortByChange(e)}
                                >
                                    <option value="Default">Default Sorting</option>
                                    <option value="individual">Popularity</option>
                                    <option value="rating">Rating</option>
                                    <option value="newness">Newness</option>
                                    <option value="lowtohigh">Price: Low to High</option>
                                    <option value="hightolow">Price: High to Low</option>
                                    <option value="random">Random</option>
                                    <option value="nameatoz">Name: A - Z</option>
                                    <option value="nameztoa">Name: Z - A</option>
                                </select>
                            </div>

                            {/* <div className="flex flex-col py-2 mb-4">
                                <label>Stock:</label>
                                <select 
                                className="mt-2 p-2 focus border-valorHijabTheme border-2 "
                                onChange={(e) => handleDropdownSortStockChange(e)}
                                >
                                    <option value="Selectall">All Stock</option>
                                    <option value="instock">In Stock</option>
                                    <option value="outofstock">Out of Stock</option>
                                    <option value="onbackorder">On Backorder</option>
                                </select>
                            </div> */}

                            {/* <div className='flex flex-col mx-2 mb-4'>
                                <label><input type="checkbox" checked={ratings.fiveStars} onChange={handleChange} name="fiveStars" />{' '}5 only</label>
                                <label><input type="checkbox" checked={ratings.fourStars} onChange={handleChange} name="fourStars" />{' '}4 and up</label>
                                <label><input type="checkbox" checked={ratings.threeStars} onChange={handleChange} name="threeStars" />{' '}3 and up</label>
                                <label><input type="checkbox" checked={ratings.twoStars} onChange={handleChange} name="twoStars" />{' '}2 and up</label>
                                <label><input type="checkbox" checked={ratings.oneStar} onChange={handleChange} name="oneStar" />{' '}1 and up</label>
                            </div> */}



                            <div className='cursor-pointer my-4 items-center bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm text-center'
                            onClick={()=> applyFilter()}
                            >
                                Filter
                            </div>

                            
    {/* <div className="cursor-pointer my-2 w-full valor-hijab-btn bg-valorHijabTheme rounded-lg  px-4 py-2 text-sm text-center" style={{ cursor: 'pointer' }} onClick={() => applyFilter()}>Filter</div> */}

                        </div>
                    </div>                   
                </div>

                {isDataloading ? 
                
                <div className="w-70 md:w-2/3 lg:w-3/4 xl:w-70 py-4 shadow-lg rounded-lg   mt-4">
                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 lg:ml-4">
                  <div className="shadow-lg rounded-lg   border-2   relative z-20  ">
                    <Loading />

                    </div>
                     
                  </div>
                
                </div> 
                : 

                <div className="w-70 md:w-2/3 lg:w-3/4 xl:w-70 py-4 bg-valorHijabTheme">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:ml-4">
                        {filteredProducts.map(item => (
                            <div key={item.Id} className="shadow-lg rounded-lg mb-8 mt-4  border-2 bg-white  relative z-20  ">
                                <div className="rounded-lg  overflow-hidden">
                                    <div className="flex flex-col md:flex-row">
                                        <div className="flex flex-grow ">
                                            <img
                                                className="w-full rounded-lg"
                                                src={process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + item.Image_Url}
                                                alt="Logo"
                                                onClick={() => navigateToProduct(item)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col my-2 items-center'>
                                    <p style={{ cursor: 'pointer' }} 
                                    // onClick={() => {navigate('/product-details');}} 
                                    className='text-center'>{item.Item_Name}</p>                                    
                                    <div className='flex justify-between'>
                                        <div flex flex-col>
                                            <p className='font-bold text-lg'>${(item.Price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                    </div> 
                                    <div style={{ cursor: 'pointer' }} 
                                    onClick={
                                        () => {
                                        // addToCart('/product-details');
                                        setAddedItemName(item.Item_Name);
                                        showAddedDialogue(item.Id);
                                        addToCart(item, 1);
                                    }}
                                    className='md:ml-2 my-2 items-center bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm text-center'>Add to Cart</div>
                                </div>
                                
                            </div>
                        ))}
                    </div>
                </div>

                }
            </div>






        </div>        


        {showItemAdded ? (<ItemAddedModal addedItemName={addedItemName} />) : ''}





        <Footer/>

        <NotificationModal
              isOpen={isNotificationModalOpen}
              onRequestClose={closeNotificationModal}
              notificationType={notificationType}
              notificationTitle={notificationTitle}
              notificationMessage={notificationMessage}
            />
    </div>
    );
  }
  