import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { AppBar, Toolbar, IconButton, Typography, Box, useMediaQuery } from '@mui/material';

import logo from '../../assets/images/logo-08-final-1.png';
import productImage from '../../assets/images/product-sample.png';

import styled from '@emotion/styled';
import { motion } from 'framer-motion';


import { FormattedMessage, IntlProvider } from 'react-intl';
import messages from '../widget/Messages'; // Import your language messages


import { Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LanguageIcon from '@mui/icons-material/Language';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingBagOutlinedIcon from '@mui/icons-material/LocalMallSharp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { AiOutlineShopping } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";

import { AES } from 'crypto-js';


import NotificationModal from '../modals/NotificationModal';


import { ClipLoader } from 'react-spinners'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


//
import axiosInstance from '../../axiosConfig'; // Ensure the correct relative path
import { setCookie, isAuthenticated } from '../../authUtils'; // Ensure the correct relative path
import { jwtDecode } from 'jwt-decode';
import { getCookie, deleteCookie } from '../../authUtils'; // Import getCookie function
//



const SlideInMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure the menu is on top of other content */
  overflow-x: hidden;
`;
const MenuContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;
const menuItemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.5 } },
};


// Styled component for the sliding div
const SlideInDiv = styled(motion.div)`
  position: absolute;
  top: 5rem;
  left: 2rem;
  right: 2rem;
  width: calc(100% - 4rem);
  height: 75%;
  background-color: white; /* Change color as needed */
`;
const slideInVariants = {
  hidden: { height: 0 },
  visible: { height: '75%', transition: { duration: 0.3, type: 'tween' } }
};
const SlideInContent = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  height: 100%;
`;

// Styled component for the sliding bag
const SlideInBag = styled(motion.div)`
  position: fixed;
  top: 0rem;
  right: 0;
  width: calc(80% - 2rem);
  height: 100%;
  /*background-color: rgba(0, 0, 0, 0.5);  Semi-transparent background */
  z-index: 1000; /* Ensure the menu is on top of other content */
  overflow-x: hidden;

  @media (min-width: 960px) {
    width: 40%;
  }

  @media (max-width: 959px) {
    width: 100%; /* Full width on mobile screens */
    left: 0;
  }
`;
const BagContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 83%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 959px) {
    width: 100%; /* Full width on mobile screens */
    left: 0;
  }
`;
const BagContentInner = styled.div`
  height: 100%; /* Ensure the inner content fills the container */
  max-height: 100%; /* Limit the height to the container's height */
  /*overflow-y: auto;  Enable vertical scrolling if content overflows */
`;
const bagItemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.5 } },
};

// Styled component for the sliding account
const SlideInAccount = styled(motion.div)`
  position: fixed;
  top: 0rem;
  right: 0;
  width: calc(100% - 2rem);
  height: 70vh;
  /*background-color: rgba(0, 0, 0, 0.5);  Semi-transparent background */
  z-index: 1000; /* Ensure the menu is on top of other content */
  overflow-x: hidden;

  @media (min-width: 960px) {
    width: 40%;
  }
`;
const AccountContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;
const AccountContentInner = styled.div`
  height: 100%; /* Ensure the inner content fills the container */
  max-height: 100%; /* Limit the height to the container's height */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
`;
const accountItemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.5 } },
};
//


function Header({ options, cart, setSiteLanguage, updateCart, removeAllCartItem }) {

  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery('(min-width:960px)');
  
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [menuWidthSignin, setMenuWidthSignin] = useState(0);
  const [menuWidthRegister, setMenuWidthRegister] = useState(0);


  const [isHoverCheckout, setIsHoverCheckout] = useState(false);
  const handleHoverCheckout = () => { setIsHoverCheckout(true); };
  const handleLeaveCheckout = () => { setIsHoverCheckout(false); };

  const [isHoverMenu, setIsHoverMenu] = useState(false);
  const handleHoverMenu = () => { setIsHoverMenu(true); };
  const handleLeaveMenu = () => { setIsHoverMenu(false); };

  const [isHoverCreateAccount, setIsHoverCreateAccount] = useState(false);
  const handleHoverCreateAccount = () => { setIsHoverCreateAccount(true); };
  const handleLeaveCreateAccount = () => { setIsHoverCreateAccount(false); };
  
  const [isHoverSignIn, setIsHoverSignIn] = useState(false);
  const handleHoverSignIn = () => { setIsHoverSignIn(true); };
  const handleLeaveSignIn = () => { setIsHoverSignIn(false); };








    //notification modal
    const [notificationType, setNotificationType] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const openNotificationModal = (type, title, message) => {
      setNotificationType(type);
      setNotificationTitle(title);
      setNotificationMessage(message);
  
      setIsNotificationModalOpen(true);
    };
    const closeNotificationModal = () => {
      setIsNotificationModalOpen(false);
    };
    //notification modal



  const [cartItems, setCartItems] = useState(cart);
  useEffect(() => {
    setCartItems(cart);

  }, [cart]); 

  
  const handleIncreaseQuantity = (item) => {
    const updatedCart = cartItems.map((cartItem) =>
    cartItem.Id === item.Id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
  );
  setCartItems(updatedCart);
  localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
  
  updateCart();
  calculateCartSubTotal();
  };

  const handleDecreaseQuantity = (item) => {
    if (item.quantity > 1) {
      const updatedCart = cartItems.map((cartItem) =>
          cartItem.Id === item.Id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
      );
      setCartItems(updatedCart);
      localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
    } else {
      const updatedCart = cartItems.filter((cartItem) => cartItem.Id !== item.Id);
      setCartItems(updatedCart);
      localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
    }
    updateCart();
    calculateCartSubTotal();
  };

  const calculateCartSubTotal = () => {
    let subTotal = 0;
    cartItems.forEach((item) => {
      subTotal += findLowestPrice(item) * item.quantity;
    });
    return subTotal;
  };

  function findLowestPrice(product) {
    return product.Price;
  }
  const calculateTotal = (item) => {
    return findLowestPrice(item) * item.quantity;
  };

  const navigateToProduct = (product) => {
    const encryptedData = AES.encrypt(JSON.stringify(product), 'encryptionKey').toString();
    // navigate(`/product-details/${encodeURIComponent(encryptedData)}`);
    navigate('/product-details', { state: { encryptedData } });
  };

  const navigateToMyAccount = () => {
    navigate('/my-account', {  });
  }

  // const navigateToSignIn = () => {
    //   navigate('/sign-in', {  });
    // }

  const navigateToCheckOut = () => {
    if (cartItems.length > 0) {
      options.onMiniCartClick();
      const encryptedData = AES.encrypt(JSON.stringify(cartItems), 'encryptionKey').toString();
      navigate('/checkout', { state: { encryptedData  } });
    } else {
      // alert("Your cart is empty. Please add items to your cart.");
      openNotificationModal(false, "Valor Hijab - Checkout", "Your cart is empty. Please add items to your cart.");
    }
  };

  const navigateToCart = () => {
    if (cartItems.length > 0) {
      options.onMiniCartClick();
      const encryptedData = AES.encrypt(JSON.stringify(cartItems), 'encryptionKey').toString();
      navigate('/cart', { state: { encryptedData } });
    } else {
      // alert("Your cart is empty. Please add items to your cart.");
      openNotificationModal(false, "Valor Hijab - Checkout", "Your cart is empty. Please add items to your cart.");
    }
  };





  const languages = [
    { code: 'en', label: 'English', flag: '🇺🇸' },
    { code: 'fr', label: 'French', flag: '🇫🇷' },
    { code: 'es', label: 'Spanish', flag: '🇪🇸' },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    setSiteLanguage(e.target.value);
  };

  const [loginEmailAddress, setLoginEmailAddress] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isDefaultModalOpen, setDefaultModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [registrationStatus, setRegistrationStatus] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(false);

  const isValidEmail = (email) => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};



  const loginUser = async (e) => {
    e.preventDefault();
    setErrorMessage({ message: '' });
  
    setIsLoading(true);
  
    // setLoginEmailAddress();
    // setLoginPassword();
  
    if (loginEmailAddress === 'Enter your email' || loginEmailAddress === '' 
    || 
    loginPassword === 'Enter your password' || loginPassword === ''
    ) {
      setErrorMessage({ message: 'Login Failed: Please enter valid credentials' });
      // setRegistrationStatus("Failed");
      setIsLoading(false);
      return;
    }
  
    //alert("login user: " + loginEmailAddress + " " + loginPassword);
    try {

      const requestData = {   
        email: loginEmailAddress,  
        password: loginPassword
      };


      const response = await axios.post(process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_USER_LOGIN, requestData, {
        headers: {
              // 'Content-Type': 'multipart/form-data',
              'Content-Type': 'application/json',
          },
      });

      setIsLoading(false);
      // alert("login: " + JSON.stringify(response.data, null, 2));
// return;

      if (response.data.status) {
        // If login is successful
        setErrorMessage({ message: '' });
        
        setLoginEmailAddress('');
        setLoginPassword('');




        const token = response.data.token;
        const decodedToken = jwtDecode(token);
        // alert(JSON.stringify(decodedToken), null, 2);
          
        const expirationDays = (decodedToken.exp - decodedToken.iat) / (24 * 60 * 60);
        // alert(expirationDays * (24 * 60 * 60)); //seconds
  
        setCookie('valorhijab-user-token', token, expirationDays);
        setCookie('valorhijab-userDetails', JSON.stringify(response.data.userData));

        



        // alert("Login Successful: " + response.data.message);
        openNotificationModal(true, "Login", response.data.message);
        
        gotoUserProfile();
      } else {
        const errors = response.data.errors.map(error => error.msg);
        setErrorMessage({ message: response.data.message, errors });
        //alert("Failed1");
      }
    } catch (error) {
      setIsLoading(false);
    
      if (error.response && error.response.data && error.response.data.message) {
      const errorMessage = error.response.data.message;
      setErrorMessage({ message: errorMessage });
    } else if (error.response && error.response.data && error.response.data.errors) {
      const { errors } = error.response.data;
      const errorMessages = errors.map(error => error.msg);
      const errorMessage = errorMessages.join(', '); // Join all error messages
      setErrorMessage({ message: errorMessage });
    } else {
      setErrorMessage({ message: 'Login failed. Please check your credentials and try again.' });
    }
  }
  };

  const [registrationFirstname, setRegistrationFirstname] = useState('');
  const [registrationLastname, setRegistrationLastname] = useState('');
  const [registrationEmailAddress, setRegistrationEmailAddress] = useState('');

  const registerUser = async (e) => {
    e.preventDefault();
    setErrorMessage({ message: '' });
    //alert("");

    setIsLoading(true);

    if (registrationEmailAddress === 'Enter your email' || registrationEmailAddress === '' 
    || 
    registrationFirstname === 'Enter your Firstname' || registrationFirstname === ''
    || 
    registrationLastname === 'Enter your Lastname' || registrationLastname === ''
    ) {
      setErrorMessage({ message: 'Registration Failed: Please enter valid credentials' });
      // setRegistrationStatus("Failed");
      setIsLoading(false);
    
      //alert("");
      return;
    }

    //alert("login user: " + emailAddress + " " + firstname + " " + lastname);

    try {
      const requestData = {   
        firstname: registrationFirstname,  
        lastname: registrationLastname,  
        email: registrationEmailAddress,  
        password: "password"
      };

      // alert(JSON.stringify(requestData, null, 2));
    
      const response = await axios.post(process.env.REACT_APP_API_SERVER_URL + '/response/vh-register-user.php', requestData, {
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Content-Type': 'application/json',

        },
      });
    
      setIsLoading(false);
      // alert(JSON.stringify(response.data, null, 2));
        // return;
    
      if (response.data.status) {

        

        // If registration is successful
        setErrorMessage({ message: '' });

        //handleEmailAddress(emailAddress);
        
        // navigate('/confirm-email/' + emailAddress);
        //navigate('/confirm-email');

        setLoginEmailAddress(registrationEmailAddress);
        setLoginPassword('');

        setRegistrationFirstname('');
        setRegistrationLastname('');
        setRegistrationEmailAddress('');

        

        // alert(response.data.message + "\n\n Please check your mail for a link to reset your password");
        
        openNotificationModal(true, "Registration", response.data.message + "\n\n Please check your mail for a link to reset your password");
        
        toggleAccountForSignIn();
      } else {
        // If there are errors in the response
        const errors = response.data.errors.map(error => error.msg);
        const errorMessage = errors.join(', ');
        setErrorMessage({ message: errorMessage });
        // alert("Registration Failed");

        openNotificationModal(false, "Registration Error", "Registration Failed");
      }
    } catch (error) {
      setIsLoading(false);
    
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        setErrorMessage({ message: errorMessage });
      } else if (error.response && error.response.data && error.response.data.errors) {
        const { errors } = error.response.data;
        const errorMessages = errors.map(error => error.msg);
        const errorMessage = errorMessages.join(', '); // Join all error messages
        setErrorMessage({ message: errorMessage });
      } else {
        setErrorMessage({ message: 'Registration failed. Please check your credentials and try again.' });
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [isBagOpen, setIsBagOpen] = useState(false);
  const toggleBag = () => {
    setIsBagOpen(!isBagOpen);
  };

  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const toggleAccount = () => {
    setIsAccountOpen(!isAccountOpen);
  };

  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const toggleAccountForSignUp = () => {
    setErrorMessage({ message: '' });
    setIsSignUpOpen(!isSignUpOpen);
  }  

  const toggleAccountForSignIn = () => {
    setErrorMessage({ message: '' });
    setIsSignUpOpen(false);
  }


  const [isToggleProfileMenuOpen, setIsToggleProfileMenuOpen] = useState(false);
  const toggleProfileMenu = () => {
    setIsToggleProfileMenuOpen(!isToggleProfileMenuOpen);
  };


  const gotoUserProfile = () => {
    navigate('/profile-dashboard');
  }


  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const navigateToShop = () => {
    navigate('/shop');
  }




  const [products, setProductsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = debounce((e) => {
    setProductsData([]);

    const newSearchQuery = e.target.value;
  setSearchQuery(newSearchQuery);
  handleSearchData(newSearchQuery);

  
    // Add your search logic here
    // alert("Search query: " + e.target.value);

    // hit search endpoit
}, 2000);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const handleSearchData = async (query) => {    
    // alert("Here: " + searchQuery);
    
    

    setIsLoading(true);
    // let queryParams = new URLSearchParams();
    // queryParams.append("tag", "kaftan");
  
  
    const requestData = {   
      query: query
    };
  
    // alert(JSON.stringify(requestData, null, 2));
  
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/response/vh-search-items.php`, requestData, {
        headers: {
          "Content-Type": "application/json",

        },
      });
  
      setIsLoading(false);
      // alert(JSON.stringify(response.data, null, 2));
  
      if (response.data.status) {
        // alert("search-products " + JSON.stringify(response.data, null, 2));
      
        // Store the retrieved data in state variables
  
        setProductsData(response.data.products);
      } else {
        // alert("error: " + response.data.message);
      }
  
    } catch (error) {
      setIsLoading(false);
      // alert("error: " + error);
  
      setNotificationMessage(error);
      notificationType(true);
    }
  };



  
//

const SlideInSearch = styled(motion.div)`
  position: fixed;
  top: 8rem;
  right: 0;
  width: calc(100% - 2rem);
  /* height: 75vh; */
  height: 80%;
  /*background-color: rgba(0, 0, 0, 0.5);  Semi-transparent background */
  z-index: 1000; /* Ensure the menu is on top of other content */
  overflow-x: hidden;

  @media (min-width: 960px) {
    width: 40%;
  }
`;
const SearchContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* background-color: #eeeeee; */
  background-color: #000000; 
  /* background-color: rgba(255, 255, 255, 0.94); */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;
const SearchContentInner = styled.div`
  height: 100%; /* Ensure the inner content fills the container */
  max-height: 100%; /* Limit the height to the container's height */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
`;
const searchItemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.5 } },
};
//




  return (
   <div className='w-full  relative z-50'>
     <div className='
      md:px-4 lg:px-16 xl:px-24 2xl:px-64 
     '>


<div className='flex items-center justify-between h-16 px-8 md:px-16'>
   <div className="flex items-center ">
          {isLargeScreen ? (
            <div className='relative z-50'>

<img
        className="block h-16 w-auto py-1 mt-2"
        src={logo}
        alt="Logo"
        onClick={() => {navigate('/');}}
        style={{ cursor: 'pointer' }}
      />
              
            </div>
          ) : (
            <IconButton
              edge="start"
              aria-label="menu"
              sx={{ color: 'white', marginRight: 2 }} 
              onClick={toggleMenu}
            >
              <div style={{ width: '20px', height: '24px' }} className='relative z-20'>
                <div style={{ width: '20px', height: '1px', backgroundColor: 'white', marginTop: '4px', marginBottom: '6px' }}></div>
                <div style={{ width: '20px', height: '1px', backgroundColor: 'white', marginBottom: '6px' }}></div>
                <div style={{ width: '20px', height: '1px', backgroundColor: 'white' }}></div>
              </div>
            </IconButton>
          )}
        </div>



    {/* {isLargeScreen && (
  <div className='flex items-center justify-center text-white text-center relative' style={{ width: '640px' }} >
    <div className='relative' style={{ width: '640px' }}>
      <div className='flex items-center justify-center relative text-white text-sm cursor-pointer' onClick={() => navigateToShop()}>Shop</div>
    </div>      
  </div>
)} */}
  
  <div className="flex items-center">
    {isLargeScreen && (
      <div style={{ width: '300px' }} className="flex justify-end ">
        <IconButton aria-label="search" sx={{ color: 'white' }} 
        // onClick={toggleSearch}
        >
          <CiSearch />
        </IconButton>
        
        <IconButton aria-label="search" sx={{ color: 'white' }} 
              onClick={() => {

                setIsSearchOpen(false);

                if (cart.length > 0) {
                    // options.onMiniCartClick();
                    toggleBag();
                } else {
                    if (options.miniCart) {options.onMiniCartClick();}
                    // alert("Your cart is empty. Please add items to your cart.");

                    openNotificationModal(false, "Cart", "Your cart is empty. Please add items to your cart.");
                }
            }}>
              <AiOutlineShopping />
                {cart && cart.length > 0 && (
                  <div
                  className="absolute top-1 right-1 bg-green-500 text-white rounded-full w-4 h-4 flex items-center justify-center font-bold"
                  style={{ fontSize: '8px', color: "#ffffff", paddingTop: '1px', paddingLeft: '2px' }}
                  >
                    {cart.length}
                  </div>
                )}
        </IconButton>
        

 {!isAuthenticated() ?
         <IconButton aria-label="shopping cart" sx={{ color: 'white' }}
        onClick={() => {
          //navigateToSignIn();
          toggleAccount();
        }}
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
 : <IconButton aria-label="shopping cart" sx={{ color: 'white' }}
 onClick={() => {
  // toggleProfileMenu();
  gotoUserProfile();
 }}
 >
   <AccountCircleOutlinedIcon />
 </IconButton>} 


        
        {/* isLargeScreen */}
        {/* <div className="flex items-center ml-2 text-white" > 
                <Select
                value={selectedLanguage}
                onChange={(e)=> {
                  handleLanguageChange(e);
                  // setSiteLanguage(selectedLanguage);
                }
                  }
                startIcon={<LanguageIcon />}
                variant="standard"
                style={{ color: '#ffffff' }}
                >
                  {languages.map((language) => (
                    <MenuItem key={language.code} value={language.code} >
                      {language.flag} {language.label}
                    </MenuItem>
                  ))}
                </Select>
              </div> */}





            </div>
          )}
          {!isLargeScreen && (
            <div>
              <IconButton aria-label="search" sx={{ color: 'white' }} className=' relative z-20' 
              // onClick={toggleSearch}
              >
                <CiSearch />
              </IconButton>






              <IconButton aria-label="search" sx={{ color: 'white' }} className=' relative z-20' 
              onClick={() => {
                if (cart.length > 0) {
                    // options.onMiniCartClick();
                    toggleBag();
                } else {
                    if (options.miniCart) {options.onMiniCartClick();}
                    // alert("Your cart is empty. Please add items to your cart.");

                    openNotificationModal(false, "Cart Error", "Your cart is empty. Please add items to your cart.");
                    
                }
            }}>
                <AiOutlineShopping />
                {cart && cart.length > 0 && (
        <div
            className="absolute top-1 right-1 bg-green-500 text-white rounded-full w-4 h-4 flex items-center justify-center font-bold"
            style={{ fontSize: '8px', paddingTop: '1px', paddingLeft: '2px' }}
        >
            {cart.length}
        </div>
    )}
              </IconButton>


              {!isAuthenticated() ?
         <IconButton aria-label="shopping cart" sx={{ color: 'white' }}
        onClick={() => {
          //navigateToSignIn();
          toggleAccount();
        }}
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
 : <IconButton aria-label="shopping cart" sx={{ color: 'white' }}
 onClick={() => {
  // toggleProfileMenu();
  gotoUserProfile();
 }}
 >
   <AccountCircleOutlinedIcon />
 </IconButton>} 







            </div>
          )}
        </div>



<SlideInSearch 
  initial={{ x: '100%' }}
        animate={{ x: isSearchOpen ? 0 : '100%' }}
        transition={{ duration: 0.2 }} 
        // exit={{ x: '100%', transition: { type: 'spring', damping: 15, stiffness: 20 } }} // Define exit animation with spring
  // variants={bagItemVariants}
  >
    <SearchContent>
      <SearchContentInner>
      {/* <div className='fixed top-0 bg-white h-8 w-full z-50'></div> */}


      <div className='mx-2  h-full'>      
            
            <motion.span
              variants={searchItemVariants}
              initial="hidden"
              animate={isSearchOpen ? "visible" : "hidden"}
              className="text-gray-900 text-sm cursor-pointer block "
              onClick={() => {  }}
            >
              
            <div 
            // className={"mini-cart-content " + (options.miniCart ? 'mini-cart-content-toggle' : '')} 
                     style={{ maxHeight: '100%', overflowY: 'hidden' }}
                    //  style={{ maxHeight: '600px', overflowY: 'hidden' }}
                    // style={{ maxHeight: '600px', overflowY: 'hidden', position: 'relative' }}
                    >
                        
                        <div className='grid grid-cols-12 gap-4 mb-8 ' style={{ height: '80vh' }}>
                            
{

<div className='col-span-12 px-2 h-full ' style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <div className='col-span-8 shadow-xl'> */}

                            <div className='flex justify-between items-center ml-4'>
            <motion.span
              variants={searchItemVariants}
              initial="hidden"
              animate={isSearchOpen ? "visible" : "hidden"}
              className="text-white text-sm font-bold cursor-pointer block my-4"
              // onClick={() => {navigateToOnSale();}}
            >
              SEARCH
            </motion.span>
              {/* <CloseIcon onClick={toggleSearch} style={{ cursor: 'pointer' }} className="block h-8 w-auto my-4 mr-5"/> */}
              <ArrowRightAltIcon 
              onClick={toggleSearch} 
              className="block h-8 w-auto my-4 mr-5"
              
              style={{color: "#ffffff", cursor: 'pointer', width: isHoverSignIn ? '32px' : '44px', transition: 'width 0.3s ease' }} 
            onMouseEnter={handleHoverSignIn}
            onMouseLeave={handleLeaveSignIn}
            />
              
            </div>

            
            <hr style={{ borderColor: '#888888' }} className='ml-4'/>

            <div className="m-2   "
                        // style={{ maxHeight: '50vh', overflowY: 'auto' }}
                        >
                            <div className="">
                                <div className=" "/>
                                <div className=" mx-4 mt-4" id="customer_login">
                                    <div className="justify-center"  style={{ overflowY: 'auto' }}>
                                        
                                        <div className="   ">
                                                {/* <label htmlFor="username">Username or email address&nbsp;<span
                                                    className="required">*</span></label> */}
                                                <input type="text"
                                                // value={searchQuery}
                                                placeholder="search .."
                                                // onChange={(e) => setLoginEmailAddress(e.target.value)}
                                                       className="  "
                                                       name="searchQuery" 
                                                       id="searchQuery" 
                                                       autoComplete="searchQuery"
                                                       onChange={handleSearchChange}
                                                       />
                                            </div>



                                            
                                            


                                        {/* </form> */}
                                    </div>                                    
                                </div>
                            </div>


                        </div>


{ (products && products.length > 0 ) ?
            <div className="m-2 mb-10  "
                        style={{ maxHeight: '60vh', overflowY: 'auto' }}
                        >
                            <div className="mb-8" style={{ }}>
                                <div className=" "/>
                                <div className=" mx-4 " >
                                    <div className="justify-center"  style={{ overflowY: 'auto' }}>
                                        
                                                             
                                            <div className="    mt-4" style={{ 
                                              backgroundColor: '#ffffff' 
                                              }}>
                                                {/* <label htmlFor="password">Password&nbsp;<span
                                                    className="required">*</span></label> */}



<div className="product-grid">
                {products.map(product => (
                    <div key={product.id} className="product-container" onClick={() => { navigate(`/product-details/${product.slug}`); }}>




                        <div 
                            // onClick={(e) => handleProductClick(item, e)} 
                            >
                              <img loading="lazy" 
                              className='px-2'
                              src={process.env.REACT_APP_API_SERVER_900_IMAGE_PATH + product.productImages[0]}
                               alt=""
                               
                              //  onMouseEnter={() => setZoomedItemId(item.id)}
                              //  onMouseLeave={() => setZoomedItemId(null)}
                               style={{
                                // transform: zoomedItemId === item.id ? 'scale(1.1)' : 'scale(1)',
                                // transition: 'transform 0.3s ease',
                                
                            }}

                               />
                            </div>

                            <div className="mt-4 w-full">
                            <h4 className='text-left pl-2 flex items-center mt-1' style={{ cursor: 'pointer' }}>
                                                            <div 
                                                            // onClick={(e) => handleProductClick(item, e)} 
                                                            className='text-xs text-black uppercase'>
                                                                {product.name}
                                                            </div>
                                                        </h4>
                                                        <h4 className='text-left pl-2 flex items-center mt-1' style={{ cursor: 'pointer' }}>
                                                            <div 
                                                            // onClick={(e) => handleProductClick(item, e)} 
                                                            className='text-sm font-bold text-black'>
                                                            {'£'}
                                                            {findLowestPrice(product)}
                                                            </div>
                                                        </h4></div>
                            
                    </div>
                ))}
            </div>


                                            </div>
                                         
                                            
                                    </div>                                    
                                </div>
                            </div>


                        </div> :  

<div className="m-2 mb-10  "
                        style={{ maxHeight: '60vh', overflowY: 'auto' }}
                        >
                            <div className="mb-8" style={{ }}>
                                <div className=" "/>
                                <div className=" mx-4 " >
                                    <div className="justify-center"  style={{ overflowY: 'auto' }}>
                                        
                                                             
                                            <div className="    mt-4" style={{ 
                                              backgroundColor: '#000000' 
                                              }}>
                                                






                        {
                          (searchQuery != "" ) ? <div className="flex justify-center mb-4">
                          <ClipLoader size={50} color={"#ffffff"} loading={true} /> 
                        </div> : <div></div>
                        } 








                                            </div>
                                         
                                            
                                    </div>                                    
                                </div>
                            </div>


                        </div>


}


                       

                            </div> 

                            }
                        </div>

                        
                        


                        



                    </div>

                    
                    
            </motion.span>

            
            
          </div>

          

          
        </ SearchContentInner>
    
    </SearchContent>
  </SlideInSearch>







  {/* Slide-in menu */}
  <SlideInMenu
    initial={{ x: '-100%' }}
    animate={{ x: isMenuOpen ? 0 : '-100%' }}
    transition={{ duration: 0.4 }}
  >
    <MenuContent>
      <div className='mx-8'>
        <div className='flex justify-between items-center'>
          <img
            className="block h-8 w-auto my-4"
            src={logo}
            alt="Logo"
            onClick={() => {navigate('/');}}
            style={{ cursor: 'pointer' }}
          />
          <ArrowBackOutlinedIcon className='text-valorHijabTheme' onClick={toggleMenu} style={{ cursor: 'pointer' }}/>
        </div>
        <hr />
        {/* Apply variants to each menu item */}
        <motion.span
          variants={menuItemVariants}
          initial="hidden"
          animate={isMenuOpen ? "visible" : "hidden"}
          className="text-sm text-valorHijabTheme cursor-pointer block my-4"
          onClick={() => {navigateToShop();}}
        >
          SHOP
        </motion.span>
        

        {/* <motion.span
          variants={menuItemVariants}
          initial="hidden"
          animate={isMenuOpen ? "visible" : "hidden"}
          className="text-gray-900 text-sm font-bold cursor-pointer block my-4"
          // onClick={() => {navigateToOnSale();}}
        >
          ON SALE
        </motion.span> */}
      </div>
    {/* Fixed div at the bottom */}
    <div className="fixed bottom-0 left-0 w-full py-4">
      
<div className='mx-8'>
<hr className='my-2'/>
{/* <div className='flex justify-between items-center'>
  <a
    className="text-gray-900 text-sm font-bold cursor-pointer block my-2"
    onClick={() => { }}
  >
    CONNECT TO YOUR ACCOUNT
  </a>
  <AccountCircleOutlinedIcon onClick={toggleMenu} style={{ cursor: 'pointer' }}/>
</div> */}

<div className='flex justify-between items-center'>
  <span
    className="text-gray-900 text-sm cursor-pointer block my-2"
    onClick={() => { /* Handle navigation */ }}
  >
    CURRENCY: <a className='ml-2'><strong>USD</strong></a> 
  </span>
  <a onClick={() => {}} style={{ cursor: 'pointer' }}>Need Help ?</a>
</div>
</div>
</div>

</MenuContent>
  </SlideInMenu>


  <SlideInBag 
initial={{ x: '100%' }}
    animate={{ x: isBagOpen ? 0 : '100%' }}
    transition={{ duration: 0.2 }}>
<BagContent>
  <BagContentInner>


  <div className='mx-2 '>      
        
        <motion.span
          variants={bagItemVariants}
          initial="hidden"
          animate={isBagOpen ? "visible" : "hidden"}
          className="text-gray-900 text-sm cursor-pointer block "
          onClick={() => {  }}
        >
          
        <div 
        style={{ maxHeight: '100vh', overflowY: 'hidden' }}>                        
                    <div className='grid grid-cols-12 gap-4 mb-8'>

{/* {
isLargeScreen ? 

<div className='md:col-span-3 md:px-2 md:mt-2' style={{  }}>
<div className="mt-4 ">
<div className='ml-2 mb-4'>Similar Products:</div>

<div className='scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100' style={{ height: 'calc(100vh - 80px)', overflowY: 'auto' }}>
  
      {cart.map((item, index) => (
        <li key={index} className="my-2 mb-6">
          <div className="">
            <div className='mx-2' style={{ cursor: 'pointer' }}>
              <img style={{ width: '120px' }} loading="lazy" 
              // src={mainProductImage(item)}
               alt=""/>
            </div>
          </div>
          <div className="flex flex-col mt-1 mx-2">
            <span style={{ cursor: 'pointer' }} onClick={() => {navigateToProduct(item)}}>
              <a className='text-xs text-gray-900 '>{item.name}</a>
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => {navigateToProduct(item)}}>
              <a className='text-xs text-gray-900'>
              {'$'}{findLowestPrice(item).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </a>
            </span>
          </div>
        </li>
      ))}

</div>

</div>
</div>

: ''
} */}
                        

<div className='relative col-span-12 md:col-span-12 px-2' style={{ boxShadow: '0px 0px 20px 0px rgba(0,0,0,1)', height: '100vh', overflowY: 'auto' }}>
<div className='relative' style={{ minHeight: '100%', paddingBottom: '100px' }}>
<div className='flex justify-between items-center mx-4 '>
  <motion.span
    variants={bagItemVariants}
    initial="hidden"
    animate={isBagOpen ? "visible" : "hidden"}
    className="text-gray-900 text-sm cursor-pointer block my-4"
    // onClick={() => {navigateToOnSale();}}
  >
    SHOPPING BAG {'(' + cart.length + ')'}
  </motion.span>
  <ArrowRightAltIcon 
  onClick={toggleBag} 
  style={{ cursor: 'pointer', width: isHoverMenu ? '32px' : '44px', transition: 'width 0.3s ease' }} 
  className="block h-8 w-auto my-4"
  
        onMouseEnter={handleHoverMenu}
        onMouseLeave={handleLeaveMenu}
  />
  
  
</div>
<hr style={{ borderColor: '#888888' }} className='ml-4 mb-8'/>
{/* <p className='flex justify-center mb-2 text-black '>Free shipping!</p> */}
<div className='bg-valorHijabTheme mx-4 mb-6' style={{ height: '8px' }}></div>


<div className='' style={{ height: 'calc(100vh - 320px)', overflowY: 'auto' }}>
  
  <div className="my-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100" style={{ maxHeight: '95%', overflowY: 'auto' }}>
  {cart && cart.map((item, index) => (
    <div key={index} className="px-4" onClick={() => { /* navigateToProduct(item) */ }}>
      <div className="flex">
        <img style={{ width: '80px' }} 
        // src={mainProductImage(item)}
        // src={productImage}
        src={process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + item.Image_Url}
        />
        <div className="ml-4 w-full">
          <div className='flex justify-between'>
            <div className='uppercase' to={item.link}>{item.Item_Name}</div>
            
            <CloseIcon 
            onClick={(e) => removeAllCartItem(e, item)} 
            className=" bg-white rounded-md border border-gray-300 hover:border-black hover:text-black" 
            style={{ cursor: 'pointer', width: '22px', height: '22px', color: "#cccccc"}}
            />
          </div>
          <div className='uppercase' to={item.link}>Size: {}</div>
          <div className='flex items-center justify-between'>
            <div className="flex" style={{ display: 'flex', alignItems: 'center' }}>
              <div className='flex bg-white items-center justify-center my-2 py-1 rounded-md' style={{ height: '70%', width: '100%' }}>
                <RemoveIcon className='' style={{ cursor: 'pointer', width: '30px', height: '16px', borderRight: '1px solid #ccc' }} 
                onClick={() => { handleDecreaseQuantity(item) }} />
                <span className='flex justify-center items-center text-center text-sm' style={{ width: '30px' }}>{item.quantity}</span>
                <AddIcon className='' style={{ cursor: 'pointer', width: '30px', height: '16px', borderLeft: '1px solid #ccc' }} 
                onClick={() => { handleIncreaseQuantity(item) }} />
              </div>
              <span className="ml-4">
                {/* {alert(JSON.stringify(item, null, 2))} */}
                {'$'}{findLowestPrice(item).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </span>
            </div>
            <span className="ml-4">
            {'$'}{calculateTotal(item).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          </div>
        </div>
      </div>
      <hr style={{ borderColor: '#cccccc' }} className='my-6'/>
    </div>
  ))}
</div>

</div>


<div id='thisDiv' className='absolute bottom-0 bg-white pb-8 w-full'>
  <div className='flex flex-col mx-4'>
    <div className='flex justify-between mt-4 mb-4'>
      <span className="flex uppercase">Subtotal:</span>
      <span className="flex font-bold">
      {'$'}{calculateCartSubTotal().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </span>
    </div>
    {/* <span className="flex mb-4">Have a coupon?</span> */}
    <div className='flex justify-between'>
      
      {/* <div className="view-cart-btn mr-2 flex items-center" style={{ cursor: 'pointer' }} 
      onClick={navigateToCart}
      >
        Cart {'(' + cart.length + ')'}
      </div> */}
      {/* <div onClick={navigateToCart} className='my-2 items-center bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm text-center'>View Cart {'(' + cart.length + ')'}</div> */}
      <div className="valor-hijab-btn bg-valorHijabTheme rounded-lg mt-4 px-4 py-2 text-sm text-center" style={{ cursor: 'pointer' }} onClick={navigateToCart}>View Cart {'(' + cart.length + ')'}</div>
             

      {/* <div className="checkout-btn ml-2 flex items-center" style={{ cursor: 'pointer' }} 
      onClick={navigateToCheckOut}
      >
        Checkout 
        <ArrowRightAltIcon 
        onClick={toggleAccount} 
        style={{ cursor: 'pointer', width: isHoverCheckout ? '32px' : '44px', transition: 'width 0.3s ease' }} 
        className="ml-2"
        onMouseEnter={handleHoverCheckout}
        onMouseLeave={handleLeaveCheckout}
        />
      </div> 
      {/* <div onClick={navigateToCheckOut} className='my-2 items-center bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm text-center'>Checkout</div> */}
      <div className="valor-hijab-btn bg-valorHijabTheme rounded-lg mt-4 px-4 py-2 text-sm text-center" style={{ cursor: 'pointer' }} onClick={navigateToCheckOut}>Checkout</div>
             

    </div>
  </div>
</div>
</div>
</div>


                    </div>                        

                </div>

                
                
        </motion.span>

        
        
      </div>

      
    </ BagContentInner>

</BagContent>
  </SlideInBag>




  


  {/* )} */}


{/* {isAccountOpen && ( */}
<SlideInAccount 
  initial={{ x: '100%' }}
        animate={{ x: isAccountOpen ? 0 : '100%' }}
        transition={{ duration: 0.2 }} 
        // exit={{ x: '100%', transition: { type: 'spring', damping: 15, stiffness: 20 } }} // Define exit animation with spring
  // variants={bagItemVariants}
  >
    <AccountContent>
      <AccountContentInner>
      <div className='fixed top-0 bg-valorHijabTheme h-8 w-full z-50'></div>


      <div className='mx-2  h-full'>      
            
            <motion.span
              variants={bagItemVariants}
              initial="hidden"
              animate={isAccountOpen ? "visible" : "hidden"}
              className="text-gray-900 text-sm cursor-pointer block "
              onClick={() => {  }}
            >
              
            <div 
            // className={"mini-cart-content " + (options.miniCart ? 'mini-cart-content-toggle' : '')} 
                     style={{ maxHeight: '100%', overflowY: 'hidden' }}
                    //  style={{ maxHeight: '600px', overflowY: 'hidden' }}
                    // style={{ maxHeight: '600px', overflowY: 'hidden', position: 'relative' }}
                    >
                        
                        <div className='grid grid-cols-12 gap-4 my-8 ' style={{ height: '60vh' }}>
                            
                            {/* <div className='col-span-4 px-2 mt-2' >
                                
                               
                            <div className=
                            // "bg-gray-300" 
                            "bg-white"
                            style={{ height: '100%', overflowY: 'auto' }}
                            >
                
                Wishlist
    </div>

                            </div> */}


                            {/* <div className='col-span-8 px-2' style={{ boxShadow: '0px 0px 20px 0px rgba(0,0,0,1)' }}> */}
{isSignUpOpen ? 
<div className='col-span-12 px-2 h-full ' style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <div className='col-span-8 shadow-xl'> */}

                            <div className='flex justify-between items-center ml-4'>
            <motion.span
              variants={accountItemVariants}
              initial="hidden"
              animate={isAccountOpen ? "visible" : "hidden"}
              className="text-valorHijabTheme text-sm font-bold cursor-pointer block my-4"
              // onClick={() => {navigateToOnSale();}}
            >
              CREATE AN ACCOUNT
            </motion.span>
              {/* <CloseIcon onClick={toggleAccount} style={{ cursor: 'pointer' }} className="block h-8 w-auto my-4 mr-5"/> */}
              <ArrowRightAltIcon 
              onClick={toggleAccount} 
              className="block h-8 w-auto my-4 mr-5"
              
              style={{ cursor: 'pointer', width: isHoverCreateAccount ? '32px' : '44px', transition: 'width 0.3s ease' }} 
            onMouseEnter={handleHoverCreateAccount}
            onMouseLeave={handleLeaveCreateAccount}
              />
            </div>

            
            <hr style={{ borderColor: '#888888' }} className='ml-4 '/>




                            <div className="m-2 mb-10 "
                        style={{ maxHeight: '50vh', overflowY: 'auto' }}
                        >
                            <div className="mb-8">
                                <div className=" "/>
                                <div className=" mx-4 mt-4" id="customer_login">
                                    <div className="justify-center">
                                        {/* <h2>Login</h2> */}
                                        {/* <form className="woocommerce-form woocommerce-form-login login" method="post"> */}
                                        <div className="   ">
        {/* <label htmlFor="reg_firstname">First name&nbsp;<span className="required">*</span></label> */}
        <input 
        value={registrationFirstname}
        placeholder="Enter your Firstname"
        onChange={(e) => setRegistrationFirstname(e.target.value)}
        type="text" className="  " name="firstname" id="reg_firstname" autoComplete="given-name" />
    </div>

    <div className="    mt-4">
        {/* <label htmlFor="reg_lastname">Last name&nbsp;<span className="required">*</span></label> */}
        <input 
        value={registrationLastname}
        placeholder="Enter your Lastname"
        onChange={(e) => setRegistrationLastname(e.target.value)}
        type="text" className="  " name="lastname" id="reg_lastname" autoComplete="family-name" />
    </div>


    <div className="    mt-4">
        {/* <label htmlFor="reg_email">Email address&nbsp;<span className="required">*</span></label> */}
        <input 
        value={registrationEmailAddress}
        placeholder="Enter your email"
        onChange={(e) => setRegistrationEmailAddress(e.target.value)}
        type="email" className="  " name="email" id="reg_email" autoComplete="email" />
    </div>

                                            <div className='my-2 text-sm' style={{ color: '#c2572b' }}>{errorMessage.message}</div>

                                            

                                            <p className='ml-2 mb-2 text-xs'>A link to set a new password will be sent to your email address.</p>
                                            {/* <p className='ml-2 mb-2 text-xs'>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <a href="">privacy policy</a>.</p> */}
                                            
                                            <div className='flex justify-between items-center flex-col md:flex-row '>
                                              <div className='flex flex-col justify-center invisible'>
                                              <div className="md:mr-4">
                                                <input className="" name="" type="checkbox" id="" defaultValue="forever"/>
                                                <span className='ml-2'>Remember me</span>
                                              </div>
                                              <div className="  mt-1">
                                                <a href="#">Forgot password ?</a>
                                            </div>
                                              
                                              </div>
                                              <motion.div
                    className="text-black text-xs font-bold cursor-pointer md:mr-4  z-50"
                    onClick={(e) => {if (!isLoading) registerUser(e)}}
                    onMouseEnter={() => setHoveredMenuItem('REGISTER')}
                    onMouseLeave={() => setHoveredMenuItem('REGISTERX')}
                  >
                  <div style={{ position: 'relative' }} className='text-lg my-2      '
                  // ' border-black border-2 px-4 py-1 my-2'
                  >
<span id="register-text">{isLoading ? 'Please wait..' : 'Register'}</span>
      <div className={`absolute bg-black 
      ${hoveredMenuItem === 'REGISTER' ? 'transition-all duration-300' : hoveredMenuItem === "REGISTERX" ? 'transition-all duration-300' : ''}`} 
      style={{ width: menuWidthRegister, height: '2px', 
      left: hoveredMenuItem === 'REGISTER' ? 0 : 'auto',
      right: hoveredMenuItem === 'REGISTER' ? 0 : menuWidthRegister
       }} />
</div></motion.div>
                                              {/* <button className="woocommerce-button-account mt-4 md:mt-4" style={{}} onClick={(e) => {if (!isLoading) registerUser(e)}}>                                                
                                                {isLoading ? 'Please wait..' : 'Register'}
                                              </button> */}
                                            </div>

                                            

                                            
                                            
                                            <div className='mt-4'>

                                            <div className='mt-4'>                                            
                                              <div className="mt-2 text-sm md:text-left text-center   text-white rounded-lg bg-valorHijabTheme px-4 py-2">
           
                                                <a onClick={() => toggleAccountForSignIn()}>SIGN IN</a> 
                                              </div>
                                            </div>


                                            </div>


                                        {/* </form> */}
                                    </div>                                    
                                </div>
                            </div>


                        </div>



                       

                            </div> : 
<div className='col-span-12 px-2 h-full ' style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <div className='col-span-8 shadow-xl'> */}

                            <div className='flex justify-between items-center ml-4'>
            <motion.span
              variants={accountItemVariants}
              initial="hidden"
              animate={isAccountOpen ? "visible" : "hidden"}
              className="text-gray-900 text-sm font-bold cursor-pointer block my-4"
              // onClick={() => {navigateToOnSale();}}
            >
              SIGN IN
            </motion.span>
              {/* <CloseIcon onClick={toggleAccount} style={{ cursor: 'pointer' }} className="block h-8 w-auto my-4 mr-5"/> */}
              <ArrowRightAltIcon 
              onClick={toggleAccount} 
              className="block h-8 w-auto my-4 mr-5"
              
              style={{ cursor: 'pointer', width: isHoverSignIn ? '32px' : '44px', transition: 'width 0.3s ease' }} 
            onMouseEnter={handleHoverSignIn}
            onMouseLeave={handleLeaveSignIn}
            />
              
            </div>

            
            <hr style={{ borderColor: '#888888' }} className='ml-4'/>




                            <div className="m-2 mb-10  "
                        style={{ maxHeight: '50vh', overflowY: 'auto' }}
                        >
                            <div className="mb-8">
                                <div className=" "/>
                                <div className=" mx-4 mt-4" id="customer_login">
                                    <div className="justify-center"  style={{ overflowY: 'auto' }}>
                                        {/* <h2>Login</h2> */}
                                        {/* <form className="woocommerce-form woocommerce-form-login login" method="post"> */}
                                            <div className="   ">
                                                {/* <label htmlFor="username">Username or email address&nbsp;<span
                                                    className="required">*</span></label> */}
                                                <input type="text"
                                                value={loginEmailAddress}
                                                placeholder="Enter your email"
                                                onChange={(e) => setLoginEmailAddress(e.target.value)}
                                                       className="  "
                                                       name="username" 
                                                       id="username" 
                                                       autoComplete="username"/>
                                            </div>
                                            <div className="    mt-4">
                                                {/* <label htmlFor="password">Password&nbsp;<span
                                                    className="required">*</span></label> */}


<div className="relative">
  <input className="  "
  value={loginPassword}
  placeholder="Enter your password"
  onChange={(e) => setLoginPassword(e.target.value)}
  type={passwordVisible ? 'text' : 'password'}
  name="password"
  id="password"
  autoComplete="current-password"/>

  <div className="absolute inset-y-0 right-0 flex items-center pr-2 mt-1 mr-2" 
  >
    <div tabIndex="-1" 
    onClick={() => setPasswordVisible(!passwordVisible)}
    >
      {passwordVisible ? <VisibilityOff className='p-1'/> : <Visibility className='p-1'/>}
    </div>
  </div>
</div>

                                            </div>


                                            <div className='invisible'><div className="    mt-4">
        {/* <label htmlFor="reg_email">Email address&nbsp;<span className="required">*</span></label> */}
        <input value={loginEmailAddress} 
        onChange={(e) => setLoginEmailAddress(e.target.value)}
        placeholder="Enter your email" 
        type="email" className="  " name="email" id="reg_email" autoComplete="email" />
    </div> <div className='my-2 text-sm' style={{ color: '#c2572b' }}>{errorMessage.message}</div>
                                            <p className='ml-2 mb-2 text-xs'>A random password will be sent to your email address.</p>
                                            {/* <p className='ml-2 mb-2 text-xs'>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.</p> */}
                                            </div>



                                            <div className='my-2 text-sm' style={{ color: '#c2572b' }}>{errorMessage.message}</div>

                                            <div className='flex justify-between items-center flex-col md:flex-row '>
                                              <div className='flex flex-col justify-center'>
                                              {/* <div className="md:mr-4">
                                                <input className="" name="" type="checkbox" id="" defaultValue="forever"/>
                                                <span className='ml-2'>Remember me</span>
                                              </div> */}
                                              {/* <div className="  mt-1">
                                                <a onClick={(e) => {
                                                  resetPassword(e);
                                                }} >Forgot password ?</a>
                                            </div> */}
                                              
                                              </div>

                                              <motion.div
                    className="text-black text-xs font-bold cursor-pointer md:mr-4  z-50"
                    onClick={(e) => {if (!isLoading) loginUser(e)}}
                    onMouseEnter={() => setHoveredMenuItem('SIGNIN')}
                    onMouseLeave={() => setHoveredMenuItem('SIGNINX')}
                  >
                  <div style={{ position: 'relative' }} className='text-lg my-2'
                  // ' border-black border-2 px-4 py-1 my-2'
                  >
<span id="signin-text">{isLoading ? 'Please wait..' : 'Sign in'}</span>
      <div className={`absolute bg-black 
      ${hoveredMenuItem === 'SIGNIN' ? 'transition-all duration-300' : hoveredMenuItem === "SIGNINX" ? 'transition-all duration-300' : ''}`} 
      style={{ width: menuWidthSignin, height: '2px', 
      left: hoveredMenuItem === 'SIGNIN' ? 0 : 'auto',
      right: hoveredMenuItem === 'SIGNIN' ? 0 : menuWidthSignin
       }} />
</div></motion.div>

                                              {/* <button className="woocommerce-button-account mt-4 md:mt-4" style={{}} onClick={(e) => {if (!isLoading) loginUser(e)}}>                                                
                                                {isLoading ? 'Please wait..' : 'Sign in'}
                                              </button> */}
                                            </div>

                                            
                                            
                                            <div className='mt-4'>                                            
                                              <div className="mt-2 text-sm md:text-left text-center   text-white rounded-lg bg-valorHijabTheme px-4 py-2">
           
                                                <a onClick={() => toggleAccountForSignUp()}>CREATE ACCOUNT</a> 
                                              </div>
                                            </div>


                                        {/* </form> */}
                                    </div>                                    
                                </div>
                            </div>


                        </div>



                       

                            </div>

                            }
                        </div>

                        
                        


                        



                    </div>

                    
                    
            </motion.span>

            
            
          </div>

          

          
        </ AccountContentInner>
    
    </AccountContent>
  </SlideInAccount>
{/* )} */}


<SlideInSearch 
  initial={{ x: '100%' }}
        animate={{ x: isSearchOpen ? 0 : '100%' }}
        transition={{ duration: 0.2 }} 
        // exit={{ x: '100%', transition: { type: 'spring', damping: 15, stiffness: 20 } }} // Define exit animation with spring
  // variants={bagItemVariants}
  >
    <SearchContent>
      <SearchContentInner>
      {/* <div className='fixed top-0 bg-white h-8 w-full z-50'></div> */}


      <div className='mx-2  h-full'>      
            
            <motion.span
              variants={searchItemVariants}
              initial="hidden"
              animate={isSearchOpen ? "visible" : "hidden"}
              className="text-gray-900 text-sm cursor-pointer block "
              onClick={() => {  }}
            >
              
            <div 
            // className={"mini-cart-content " + (options.miniCart ? 'mini-cart-content-toggle' : '')} 
                     style={{ maxHeight: '100%', overflowY: 'hidden' }}
                    //  style={{ maxHeight: '600px', overflowY: 'hidden' }}
                    // style={{ maxHeight: '600px', overflowY: 'hidden', position: 'relative' }}
                    >
                        
                        <div className='grid grid-cols-12 gap-4 mb-8 ' style={{ height: '80vh' }}>
                            
{

<div className='col-span-12 px-2 h-full ' style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <div className='col-span-8 shadow-xl'> */}

                            <div className='flex justify-between items-center ml-4'>
            <motion.span
              variants={searchItemVariants}
              initial="hidden"
              animate={isSearchOpen ? "visible" : "hidden"}
              className="text-white text-sm font-bold cursor-pointer block my-4"
              // onClick={() => {navigateToOnSale();}}
            >
              SEARCH
            </motion.span>
              {/* <CloseIcon onClick={toggleSearch} style={{ cursor: 'pointer' }} className="block h-8 w-auto my-4 mr-5"/> */}
              <ArrowRightAltIcon 
              onClick={toggleSearch} 
              className="block h-8 w-auto my-4 mr-5"
              
              style={{color: "#ffffff", cursor: 'pointer', width: isHoverSignIn ? '32px' : '44px', transition: 'width 0.3s ease' }} 
            onMouseEnter={handleHoverSignIn}
            onMouseLeave={handleLeaveSignIn}
            />
              
            </div>

            
            <hr style={{ borderColor: '#888888' }} className='ml-4'/>

            <div className="m-2   "
                        // style={{ maxHeight: '50vh', overflowY: 'auto' }}
                        >
                            <div className="">
                                <div className=" "/>
                                <div className=" mx-4 mt-4" id="customer_login">
                                    <div className="justify-center"  style={{ overflowY: 'auto' }}>
                                        
                                        <div className="   ">
                                                {/* <label htmlFor="username">Username or email address&nbsp;<span
                                                    className="required">*</span></label> */}
                                                <input type="text"
                                                // value={searchQuery}
                                                placeholder="search .."
                                                // onChange={(e) => setLoginEmailAddress(e.target.value)}
                                                       className="  "
                                                       name="searchQuery" 
                                                       id="searchQuery" 
                                                       autoComplete="searchQuery"
                                                       onChange={handleSearchChange}
                                                       />
                                            </div>



                                            
                                            


                                        {/* </form> */}
                                    </div>                                    
                                </div>
                            </div>


                        </div>


{ (products && products.length > 0 ) ?
            <div className="m-2 mb-10  "
                        style={{ maxHeight: '60vh', overflowY: 'auto' }}
                        >
                            <div className="mb-8" style={{ }}>
                                <div className=" "/>
                                <div className=" mx-4 " >
                                    <div className="justify-center"  style={{ overflowY: 'auto' }}>
                                        
                                                             
                                            <div className="    mt-4" style={{ 
                                              backgroundColor: '#ffffff' 
                                              }}>
                                                {/* <label htmlFor="password">Password&nbsp;<span
                                                    className="required">*</span></label> */}



<div className="product-grid">
                {products.map(product => (
                    <div key={product.id} className="product-container" onClick={() => { navigate(`/product-details/${product.slug}`); }}>




                        <div 
                            // onClick={(e) => handleProductClick(item, e)} 
                            >
                              <img loading="lazy" 
                              className='px-2'
                              src={process.env.REACT_APP_API_SERVER_900_IMAGE_PATH + product.productImages[0]}
                               alt=""
                               
                              //  onMouseEnter={() => setZoomedItemId(item.id)}
                              //  onMouseLeave={() => setZoomedItemId(null)}
                               style={{
                                // transform: zoomedItemId === item.id ? 'scale(1.1)' : 'scale(1)',
                                // transition: 'transform 0.3s ease',
                                
                            }}

                               />
                            </div>

                            <div className="mt-4 w-full">
                            <h4 className='text-left pl-2 flex items-center mt-1' style={{ cursor: 'pointer' }}>
                                                            <div 
                                                            // onClick={(e) => handleProductClick(item, e)} 
                                                            className='text-xs text-black uppercase'>
                                                                {product.name}
                                                            </div>
                                                        </h4>
                                                        <h4 className='text-left pl-2 flex items-center mt-1' style={{ cursor: 'pointer' }}>
                                                            <div 
                                                            // onClick={(e) => handleProductClick(item, e)} 
                                                            className='text-sm font-bold text-black'>
                                                            {'£'}
                                                            {findLowestPrice(product)}
                                                            </div>
                                                        </h4></div>
                            
                    </div>
                ))}
            </div>


                                            </div>
                                         
                                            
                                    </div>                                    
                                </div>
                            </div>


                        </div> :  

<div className="m-2 mb-10  "
                        style={{ maxHeight: '60vh', overflowY: 'auto' }}
                        >
                            <div className="mb-8" style={{ }}>
                                <div className=" "/>
                                <div className=" mx-4 " >
                                    <div className="justify-center"  style={{ overflowY: 'auto' }}>
                                        
                                                             
                                            <div className="    mt-4" style={{ 
                                              backgroundColor: '#000000' 
                                              }}>
                                                






                        {
                          (searchQuery != "" ) ? <div className="flex justify-center mb-4">
                          <ClipLoader size={50} color={"#ffffff"} loading={true} /> 
                        </div> : <div></div>
                        } 








                                            </div>
                                         
                                            
                                    </div>                                    
                                </div>
                            </div>


                        </div>


}


                       

                            </div> 

                            }
                        </div>

                        
                        


                        



                    </div>

                    
                    
            </motion.span>

            
            
          </div>

          

          
        </ SearchContentInner>
    
    </SearchContent>
  </SlideInSearch>






  </div>
</div>

<NotificationModal
              isOpen={isNotificationModalOpen}
              onRequestClose={closeNotificationModal}
              notificationType={notificationType}
              notificationTitle={notificationTitle}
              notificationMessage={notificationMessage}
            />


   </div>
  );
}


function debounce(func, delay) {
  let debounceTimer;
  return function(...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
  }
}



export default Header;
