import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useState, useEffect } from 'react';


import HomePage from "./components/pages/HomePage";
import ShopPage from "./components/pages/ShopPage";
import CheckoutPage from "./components/pages/CheckoutPage";
import CartPage from "./components/pages/CartPage";
import ProductDetails from "./components/pages/ProductDetails";

import ProfileDashboardPage from "./components/auth-pages/ProfileDashboardPage"

import ProtectedRoute from './protectedRoute';


function App() {



  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('valor-hijab-cart')) || []);

  const addToCart = (product, count) => {
    //alert("Product:" + product + " Count:" + count + ", added !");
  
    const existingProduct = cart.find((item) => item.Id === product.Id);
    //alert("Existing Product:", existingProduct);
    
    if (existingProduct) {
        const updatedCart = cart.map((item) =>
            item.Id === product.Id ? { ...item, quantity: item.quantity + (count ? count : 1) } : item
        );
        //console.log("Updated Cart:", updatedCart);
        setCart(updatedCart);
        localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
    } else {
        const updatedCart = [...cart, { ...product, quantity: count ? count : 1 }];
        //console.log("Updated Cart:", updatedCart);
        setCart(updatedCart);
        localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
    }
  
    //alert(JSON.stringify(localStorage.getItem('valor-hijab-cart'), null, 2));
    updateCart();
  };
  
  
  
  const clearCart = () => {
    setCart([]); // Clear cart state
    localStorage.removeItem('valor-hijab-cart'); // Remove cart from localStorage
  };
  
    
  // Update cart items function
  const updateCart = () => {
    const updatedCart = JSON.parse(localStorage.getItem('valor-hijab-cart')) || [];
    setCart(updatedCart);
  };
  
  
  // Remove item totally from cart
  const removeCartItem = (e, itemToRemove) => {
    e.stopPropagation();
  
    const updatedCart = cart.map((item) =>
          item.Id === itemToRemove.Id ? { ...item, quantity: item.quantity - 1 } : item
      ).filter((item) => item.quantity > 0);
  
      setCart(updatedCart);
      localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
  
      updateCart();
  };
  
  const removeAllCartItem = (e, itemToRemove) => {
    e.stopPropagation();
  
    // Filter out the item to be removed from the cart
    const updatedCart = cart.filter((item) => item.Id !== itemToRemove.Id);
  
    // Update the cart state
    setCart(updatedCart);
  
    // Update the cart in local storage
    localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
  
    // Update the cart
    updateCart();
  };


  const [showMiniCart, setShowMiniCart] = useState(false);
const [showSideInfo, setShowSideInfo] = useState(false);
const [showMobileNav, setShowMobileNav] = useState(false);
  const HandelMiniCartStatus = () => {
    setShowMiniCart(!showMiniCart);
};
const HandelSideInfoStatus = () => {
    setShowSideInfo(!showSideInfo);
};
const HandelMobileNavStatus = () => {
    setShowMobileNav(!showMobileNav);
};
  const options = {
    sideInfo: showSideInfo,
    mobileNav: showMobileNav,
    miniCart: showMiniCart,
    onSideInfoClick: HandelSideInfoStatus,
    onMobileNavClick: HandelMobileNavStatus,
    onMiniCartClick: HandelMiniCartStatus
  };

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const setSiteLanguage = (lan) => {
    setSelectedLanguage(lan);
  };




  return (
    <Router>
      <Routes>
        <Route path="/*" element={<div>Not Found</div>} />

        <Route path='/' element={        
        <HomePage options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem}
        />}/>
        
        <Route path='/shop' element={        
        <ShopPage options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem}
        />}/>  
        
        <Route path='/checkout' element={        
        <CheckoutPage options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem} 
        clearCart={clearCart}
        />}/>
        
        <Route path='/cart' element={        
        <CartPage options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem} 
        // clearCart={clearCart}
        />}/> 

        <Route path='/product-details' element={        
        <ProductDetails options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem} 
        // clearCart={clearCart}
        />}/>  
        
        
        
        <Route path='/profile-dashboard' element={<ProtectedRoute><ProfileDashboardPage 
        options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem} 
        /></ProtectedRoute> }/>
        
        
              
      </Routes>      
    </Router>
  );
}

export default App;
